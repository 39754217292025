/* global $ */

function deleteFromInput($input, value) {
  var valueWithSuffixLast = ';' + value + ' (ala)'
  var valueWithSuffixMiddle = ';' + value + ' (ala)' + ';'
  var valueWithSuffixFirst = value + ' (ala)' + ';'
  var valueWithSuffix = value + ' (ala)'
  var valueLast = ';' + value
  var valueMiddle = ';' + value + ';'
  var valueFirst = value + ';'

  var iValues = $input.val()

  iValues = iValues.trim()

  if (iValues.includes(valueWithSuffixMiddle)) {
    iValues = iValues.replace(valueWithSuffixMiddle, ';')
  }

  if (iValues.startsWith(valueWithSuffixFirst)) {
    iValues = iValues.replace(valueWithSuffixFirst, '')
  }

  if (iValues.endsWith(valueWithSuffixLast)) {
    iValues = iValues.replace(valueWithSuffixLast, '')
  }

  if (iValues.includes(valueMiddle)) {
    iValues = iValues.replace(valueMiddle, ';')
  }

  if (iValues.startsWith(valueFirst)) {
    iValues = iValues.replace(valueFirst, '')
  }

  if (iValues.endsWith(valueLast)) {
    iValues = iValues.replace(valueLast, '')
  }

  if (iValues == valueWithSuffix) {
    iValues = iValues.replace(valueWithSuffix, '')
  }

  if (iValues == value) {
    iValues = iValues.replace(value, '')
  }

  $input.val(iValues)
}


$(document).ready(function() {

  var $searchCriterion = $('#modal-jobwatch-popup .search-criterion')
  var $areaValues = $('#modal-jobwatch-popup input[name="municipality"]')
  var $searchValues = $('#modal-jobwatch-popup input[name="search"]')

  $searchCriterion.click(function(e) {
    var value = $(this).data('name')

    e.preventDefault()

    deleteFromInput($areaValues, value)
    deleteFromInput($searchValues, value)
    $(this).remove()
  });
});